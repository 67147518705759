@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.contact-header-background {
  background-size: cover;
  background-repeat: no-repeat;
  height: 260px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow", sans-serif !important;
}

.hidden-text {
  position: absolute;
  opacity: 0;
  bottom: 0;
  top: 0;
  z-index: -2;
}

.navbar-nav li,
.dropdown-menu a {
  font-family: "Barlow", sans-serif !important;
  font-weight: 450;
}

.pFont {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
}

p,
span {
  font-family: "Roboto", sans-serif;
}

button {
  font-family: "Roboto", sans-serif;
}

.dropdown-menu {
  font-size: 13px;
  font-family: 'Nunito', sans-serif;
}

@media (max-width: 768) {
  .port-column {
    margin-bottom: 15px;
  }
}


body {
  overflow-x: hidden;
}

.free-quote {
  border-radius: 0%;
  border: 2px solid #106ce6;
  font-weight: bolder;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.2);
}

.free-quote:hover {
  background-color: #106ce6;
  color: white;
  font-weight: bolder
}

#services-section {
  background-color: #f1f6fe;
}

.edu-card {
  border-radius: 15px;
  margin-top: 15px;
}

.card {
  box-shadow: 3px 3px 3px 3px rgba(0.1, 0.1, 0.1, 0.1);
}

.waseem-img,
.hadeed-img,
.haram-img,
.usama-img,
.maham-img,
.danish-img,
.nadeem-img,
.akhzar-img,
.ata-img {
  height: auto;
  max-width: 100%;
}

.page-header-title {
  color: #106ce6;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.products-link {
  color: #106ce6;
  font-weight: bold;
}

.products-link:hover {
  color: white;
}

.product-icon-box h4 {
  color: black;
}

.product-icon-box:hover h4 {
  color: white;
}

.product-icon-box:hover {
  color: white;
}

.product-icon-box {
  height: 100%;
}

.services-list:hover {
  color: #106ce6;
}

.services-list p {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}

.color-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 260px;
}

.background-image1 {
  background-size: cover;
  width: 100%;
  height: 260px;
  background-repeat: no-repeat;
}

.background-image3 {
  background-image: url("/src/img/bg-header.avif");
  background-size: cover;
  width: 100%;
  height: 260px;
  background-repeat: no-repeat;
}

.color-overlay1 {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 260px;
}

.background-image2 {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 260px;
}

.color-overlay2 {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 260px;
  background-color: rgba(16, 108, 230, 0.6);
}

.background-image2 {
  background-size: cover;
  background-repeat: no-repeat;
}

.color-overlay2 {
  background-size: cover;
  background-repeat: no-repeat;
}

.about-bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 71vh;
}

@media (max-width : 768px) {
  .about-bg-img {
    height: auto;
  }
}

.edu-icnos {
  font-size: 20px
}

.mission-vision {
  padding: 25px;
  border-radius: 25px;
}

.bi-binoculars,
.bi-bullseye {
  font-size: 35px
}

.slick-arrow {
  color: black !important;
}

.different-card {
  background-color: #106ce6 !important;
}

.faq-bg {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba (255, 255, 255, 0.7);
}

@media (max-width: 768px) {

  .email-phone i,
  Link {
    text-align: left;
  }
}

.portfolio-title {
  padding-bottom: 30px;
}

.portfolio-title p {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #684df4;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.portfolio-cards {
  padding: 40px;
  box-shadow: 3px 3px 3px 3px rgba(204, 204, 204, 0.9);
  border-radius: 25px;
  border-top: 8px solid #106ce6;
}

.portfolio-cards p {
  margin: 0;
  font-size: 22px;
}

@media (max-width: 768px) {
  .port- {
    padding-bottom: 10px;
  }
}

.portfolio-clients {
  height: 70px;
  padding: 10px;
}

@media (max-width: 768px) {

  .portfolio-clients {
    height: 70px;
  }
}

.email-phone {
  margin-left: 730px;
}

@media (max-width: 768px) {
  .email-phone {
    margin-left: 35px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .email-phone {
    margin-left: 250px;
  }
}

@media(min-width: 992px) and (max-width: 1280px) {
  .email-phone {
    margin-left: 400px;
  }
}

.portfolio-images {
  transition: transform 0.3s ease;
}

.portfolio-images:hover {
  transform: scale(1.1);
  background-color: rgba (0, 0, 0, 0.3);
}

.headStart {
  border: 1px solid white;
}

.headStart:hover {
  background-color: #106ce6;
}

.messengerC {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 30px;
  bottom: 70px;
  z-index: 99;
}

.whatsaapC {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 27px;
  bottom: 10px;
  z-index: 99;
}

.custom-header {
  background-color: black;
}

a {
  text-decoration: none;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #106eea;
  text-decoration: none;
}

a:hover {
  color: #3b8af2;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f9fe;
}

.section-title {
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #684df4;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 35px;
  font-weight: 700;
}

.section-title h3 span {
  color: #684df4;
}

.section-title p {
  margin: 15px auto 0 auto;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
  margin-bottom: -30px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #f1f6fe;
}

.counts .count-box i {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #684df4;
  color: #fff;
  width: 56px;
  height: 56px;
  line-height: 0;
  border-radius: 50px;
  border: 5px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #062b5b;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

/*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/

.clients img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.clients img {
  transform: scale(1);
  transition: transform 0.5s ease;
}

@media (max-width: 768px) {

  .clients img {
    max-width: 85%;
  }
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/

.services-bg {
  background-image: url(./images/services-bg.avif);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: rgb(245, 245, 245);
}

.services .container {
  padding: 120px 0px 120px 0px;
}

.services .card {
  position: relative;
  box-shadow: none;
  border: none;
  border-radius: 20px;
}

.services .card button {
  content: "";
  position: absolute;
  bottom: -8%;
  left: 43%;
}

.services .card button i {
  border-radius: 100%;
  color: white !important;
  padding: 15px;
  align-items: center;
  background-color: #684df4;
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
  font-size: 20px !important;
}

.services .icon::before {
  color: #9C8BF8;
  font-size: 50px;
  padding: 20px;
  background-color: #e2e8fa;
  clip-path: path("M4.76563 19.2144C-2.32686 32.07 -1.20075 48.6639 6.14105 61.3767C16.4024 79.1459 38.9816 89.016 58.6174 83.4451C78.2532 77.8741 92.5688 56.7417 89.6127 36.3982C84.2306 -0.647078 23.3991 -14.559 4.76563 19.2144Z");
  position: relative;
}

.shape-icon {
  position: relative;
}

.shape-icon .circle::before {
  content: "";
  position: absolute;
  background-color: #684df4;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  top: 0;
  left: 20%;
  z-index: 10;
}

.shape-icon .circle::after {
  content: "";
  position: absolute;
  background-color: #684df4;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  bottom: 4%;
  left: 5%;
  z-index: 10;
}

.service-card_number {
  position: absolute;
  font-family: "Barlow", sans-serif;
  top: 25px;
  right: 40px;
  font-size: 100px;
  line-height: 1;
  font-weight: bold;
  color: #e2e8fa;
  opacity: 0.3;
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
  background: url("/src/images/Reuse-Image.avif") no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0;
  position: relative;
}

.testimonials-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #684df4;
  opacity: 0.8;
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #106eea;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/* education benefits */
.benefits li {
  list-style: none;
}

.seo-heading-h3 {
  color: initial;
  margin: 0;
  font-size: 19px;
  font-weight: bold;
}

@media (min-width:600px) {
  .benefitsCol1 {
    position: relative;
  }

  .benefitsCol1::after {
    content: "";
    position: absolute;
    top: 13%;
    right: 2%;
    width: 3px;
    height: 30%;
    background-color: #106eea;
  }

  .benefitsCol1::before {
    content: "";
    position: absolute;
    top: 55%;
    right: 2%;
    width: 3px;
    height: 30%;
    background-color: #106eea;
  }
}

/*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
.team {
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(16, 110, 234, 0.15);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #222222;
  margin: 0 3px;
  padding-top: 7px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(104, 77, 244, .8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.team .member .social a:hover {
  background: #3b8af2;
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info p {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px !important;
  color: #222222 !important;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
.faq {
  padding: 10px 0;
  position: relative;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #d4e5fc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list .question {
  display: block;
  position: relative;
  font-family: #106eea;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 25px;
  cursor: pointer;
  color: #0d58ba;
  transition: 0.3s;
}

.faq .faq-list i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -2px;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 25px;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list .collapsed {
  color: black;
}

.faq .faq-list .collapsed:hover {
  color: #106eea;
}

.faq .faq-list .collapsed .icon-show {
  display: inline-block;
  transition: 0.6s;
}

.faq .faq-list .collapsed .icon-close {
  display: none;
  transition: 0.6s;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact-head {
  font-size: 20px !important;
  font-weight: bold;
  margin-top: 5px;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #684df4;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #a696fa;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media (min-width:600px) {
  .contactform {
    padding: 30px;
  }
}

@media (max-width:600px) {
  .contactform {
    margin-top: 30px;
  }
}

.contactform .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contactform .error-message br+br {
  margin-top: 25px;
}

.contactform .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contactform .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contactform .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contactform .form-group {
  margin-bottom: 15px;
}

.contactform input,
.contactform textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contactform input:focus,
.contactform textarea:focus {
  border-color: #106eea;
}

.contactform input {
  padding: 7px 15px;
}

.contactform textarea {
  padding: 12px 15px;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  padding: 0 0 13px 0;
  color: white;
  font-size: 14px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  /* background: #fff; */
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: white;
}

#footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  background: transparent;
  color: #fff;
  border: 1px solid white;
  padding: 12px;
  border-radius: 100%;
  line-height: 1;
  margin-right: 4px;
  text-align: center;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #3b8af2;
  color: #fff;
  border: none;
  text-decoration: none;
}

.img-fluid {
  max-height: 100%;
}

@media (max-width: 768px) {

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  #topbar {
    display: none;
  }

  .mission-vision {
    width: 100%;
    overflow: auto;
    border-radius: 25px;
    margin-bottom: 25px;

  }

  .dev-team,
  .exec-team {
    overflow: auto;
  }

  .embed-demo-video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .video-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}

.blog-container p {
  text-align: justify;
}

.blog-container h1,
.blog-container h2,
.blog-container h3,
.blog-container h4,
.blog-container h5,
.blog-container h6 {
  text-align: left;
}

.gif-design {
  margin-right: 60px;
}

.contactBg {
  background-image: url(./images/form-bg.avif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(245, 245, 245);
}

.contactOverlay {
  background-color: rgba(246, 249, 254, .97);
}

.calAction {
  padding: 60px 0;
  background: url("/src/img/testimonials-background.avif") no-repeat;
  background-position: center center;
  background-size: cover;
}

.homeHeader-overlay {
  background: linear-gradient(to right, #684df4, transparent);
}

.homeHader-overlay {
  background-color: #684df4;
  opacity: 0.85;
}

.homeHeader {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media (max-width:600px) {
  .homeHeader {
    background-position: center;
  }
}

.hero-shape1 {
  position: absolute;
  height: 430px;
  bottom: 0;
  right: 0;
  width: 215px;
  background: #684df4;
  transform: skewX(-45deg);
  opacity: 0.65;
}

@media (max-width:500px) {
  .hero-shape1 {
    z-index: -3;
  }

  .hero-shape2,
  .hero-shape3 {
    display: none;
  }
}

.hero-shape2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.hero-shape3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.py-6 {
  padding: 150px 0;
}

.ripple-shape {
  position: absolute;
  top: 17%;
  left: -40px;
  z-index: 1;
  height: 100px;
  width: 100px;
}

.headBtn1 {
  background-color: #5d3ff7 !important;
  color: white !important;
}

@keyframes heroripple {
  0% {
    transform: scale(.6);
    opacity: 0.2;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.riple1,
.riple2,
.riple3,
.riple4,
.riple5,
.riple6 {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  animation: heroripple 8s linear infinite;
}

.riple2 {
  animation-delay: 1s;
}

.riple3 {
  animation-delay: 2s;
}

.riple4 {
  animation-delay: 3s;
}

.riple5 {
  animation-delay: 4s;
}

.riple6 {
  animation-delay: 5s;
}

/* about */
.aboutSection i {
  font-size: 40px;
  margin-right: 8px;
  color: #9C8BF8;
}

.btnFill {
  background-color: #684df4 !important;
  color: white !important;
}

.aboutContact .callIcon {
  position: relative;
}

@keyframes callIcon {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

.aboutContact .callIcon::before,
.aboutContact .callIcon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #684df4;
  animation: callIcon 4s linear infinite;
}

.aboutContact .callIcon::after {
  animation-delay: 1s;
}

.aboutContact .callIcon .fas {
  background-color: #684df4;
  font-size: 30px;
  border-radius: 100%;
  padding: 15px;
  color: white;
}

.chooseH4 p {
  font-size: 18px;
  font-weight: 700;
}

.chooseH4 p i {
  font-size: 22px;
  border: 1px dashed #684df4;
  border-radius: 100%;
  padding: 9px;
}

.statsBg {
  background-image: url(./images/stats-bg.avif);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(8, 14, 28);
}

.statsBg h1,
.statsBg h6 {
  color: white;
}

.statsBg i {
  font-size: 50px;
  color: #684df4;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.statsBg .icon {
  position: relative;
  width: 130px;
  height: 130px;
}

.statsBg .icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0060ff94;
  clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
}

.statsBg .icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 100%;
  height: 100%;
  background-color: white;
  clip-path: path("M13.3648 23.689C12.3113 18.0885 13.5019 11.7494 18.6938 9.40012C35.1267 1.96429 68.7714 -9.44147 82.3112 14.0815C100.548 45.7649 138.846 59.5402 82.3112 87.5508C66.122 95.5719 56.2138 98.9992 50.3168 99.8059C42.2525 100.909 34.515 87.5508 26.3756 87.5508H13.8433C10.9933 87.5508 8.2154 86.3499 6.50037 84.0737C-12.7867 58.4757 17.3824 45.0461 13.3648 23.689Z");
}

.formContact i {
  background-color: #684df4;
  color: white;
  font-size: 24px;
  padding: 13px;
  border-radius: 5px;
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
}

.formContact span {
  margin-left: 5%;
}

.contactBg input,
.contactBg textarea {
  border-radius: 7px;
}

.footerBg {
  position: relative;
  background-image: url(./images/Reuse-Image.avif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footeroverlay {
  background-color: rgba(104, 77, 244, 0.95);
}

.footerBg h2 {
  position: relative;
  margin-bottom: 30px;
  font-size: 28px !important;
  font-weight: 600;
}

.footerBg h2::before {
  content: "";
  position: absolute;
  background-color: #22165a;
  height: 2px;
  width: 100px;
  bottom: -25%;
  left: 0;
}

.footerBg .footer-links h2::after,
.footerBg .footer-contact h2::after {
  content: "";
  position: absolute;
  background-color: white;
  width: 10px;
  height: 10px;
  border: 2px solid #684df4;
  border-radius: 100%;
  bottom: -36%;
  left: 38%;
}

.footerBg .footer-subscribe h2::after {
  content: "";
  position: absolute;
  background-color: white;
  width: 10px;
  height: 10px;
  border: 2px solid #684df4;
  border-radius: 100%;
  bottom: -36%;
  left: 22%;
}

.footerBg .footer-subscribe input::placeholder {
  color: white !important;
}

@media (max-width:568px) {

  .footerBg .footer-links h2::after,
  .footerBg .footer-contact h2::after {
    left: 28%;
  }

  .footerBg .footer-subscribe h2::after {
    left: 28%;
  }
}

.footerBg li {
  font-size: 17px;
}

.copyright h5 {
  font-size: 19px;
}

:root {
  --primary-color: #042638;
  --secondary-color: #042638;
}

a {
  text-decoration: none !important;
}

.icon-box:hover {
  background-color: #684df4;
  background-image: url(./images/services-bg.avif);
  background-position: center;
  transition: background-color 0.5s ease, background-image 0.5s ease, background-position 0.5s ease;
}

.icon-box:hover h4,
.icon-box:hover p,
.icon-box:hover i {
  color: white;
}


/* animate */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateY(50%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.fadeIn {
  animation: fadeIn 10s ease-in-out forwards;
}

.slideInLeft {
  animation: slideInLeft 5s ease-in-out forwards;
}

.bounce {
  animation: bounce 2s infinite;
}

.allHeaderP {
  padding: 140px 0;
}

.allHeaderBg {
  background-image: url(./images/Reuse-Image.avif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.allHeader-overlay {
  background: linear-gradient(to right, #684df4, transparent);
}

.productservice .card {
  box-shadow: 0 0 4px #c5c5c5;
}

.productservice i {
  font-size: 50px !important;
  color: #684df4;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

.productservice h4,
.productservice p {
  text-align: center;
}

.productservice .card {
  position: relative;
}

.productservice button i {
  content: "";
  position: absolute;
  bottom: -13%;
  left: 43%;
  border-radius: 100%;
  color: white;
  width: 50px;
  height: 50px;
  align-items: center;
  background-color: #684df4;
  box-shadow: 0px 6px 20px rgba(0, 96, 255, 0.6);
  font-size: 20px !important;
}

.productCard h4 {
  font-size: 20px;
}

.productCard:hover h4 {
  color: white;
}

.member:hover .member-info {
  background-color: #684df4;
  transition: 0.5s ease;
}

.member:hover .member-info p,
.member:hover .member-info span {
  color: white;
}

.member:hover .member-img img {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.member-img img {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.edu-card:hover .card {
  background-color: #684df4;
}

.edu-card p {
  font-weight: 600;
}

.edu-card:hover .card i,
.edu-card:hover .card p {
  color: white;
}

.whatsaapImg {
  position: fixed;
  width: 50px;
  bottom: 2%;
  left: 1%;
  z-index: 100;
}

@media (min-width:600px) {
  .mainHeaderText {
    padding: 0 40px !important;
    max-width: 600px !important;
  }
}

@media (min-width:600px) {
  .teamImg {
    width: 75% !important;
  }
}

.profile-set {
  position: absolute;
  top: 17%;
  right: 1%;
  z-index: 3;
}

.profile-set h3 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #00B6C7;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: white;
}

.profileLink h5 {
  line-height: 2.5rem;
}

.profileLink i {
  color: #684df4;
  font-size: 18px;
}

.help-card i {
  font-size: 22px;
  color: #684df4;
}

.help-card-head,
.blog-title {
  margin: 0;
  font-size: 18px;
  font-weight: 450;
}

.help_points .help-card-head {
  color: #684df4;
}

.help-card li {
  line-height: 2rem;
}

.issue-solution span,
.issue-solution h5 {
  color: #684df4;
}

.issue-solution a {
  text-decoration: underline !important;
}

.formButton a {
  text-decoration: underline !important;
  color: #684df4;
}

.formButton {
  margin: 0;
}

.btnOwner {
  background-color: #00B6C7;
  color: dark;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.btnUser {
  background-color: #684df4;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
}